import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import Spinner from "../../components/Spinner";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Modal from "../../components/Modal";

const ModalEditPorudzbina = ({ updateData, setUpdateData, setShowEditModal, fetchData }) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  console.log(updateData);

  const handleCancel = () => {
    setShowEditModal(false);
    setShowSpinner(false);
  };

  const handleSave = (e) => {
    e.preventDefault();
    setShowSaveModal(true);
  };

  const handleCancelSaveModal = () => {
    setShowSaveModal(false);
    setShowSpinner(false);
  };

  const handleConfirmedSaveModal = async () => {
    setShowSpinner(true);
    try {
      await axiosPrivate.put(`nabavke/porudzbine/${updateData?.id}`, updateData);

      toast.success(`Porudžbina ${updateData?.proFaktura} je uspešno sačuvana!`, {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      toast.error(`UPS!!! Došlo je do greške: ${error} `, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setShowSaveModal(true);
      setShowEditModal(false);
      setShowSpinner(false);
      fetchData();
    }
  };

  const handleChange = (e) => {
    setUpdateData((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  return (
    <div className="relative z-10">
      <form onSubmit={(e) => handleSave(e)}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <div className="relative w-full transform overflow-hidden rounded-lg bg-white p-4 text-left shadow-xl transition-all sm:p-8 dark:bg-gray-800">
              <div className="w-full sm:mt-0">
                {/* Modal Head */}

                <h3>Izmena porudžbine</h3>
                <div className="my-4 h-0.5 bg-zinc-400"></div>

                {/* Modal Body */}

                <div className="grid grid-cols-1">
                  {/* Podaci o reklamaciji */}
                  <div>
                    <h4>Podaci o porudžbini</h4>
                    <div className=" grid gap-4 md:grid-cols-2 ">
                      <div>
                        <label htmlFor="proFaktura">Broj Profakture/Fakture</label>
                        <input
                          type="text"
                          id="proFaktura"
                          aria-describedby="Broj Profakture/Fakture"
                          value={updateData?.proFaktura}
                          onChange={handleChange}
                          maxLength={64}
                          required
                        />
                      </div>
                      <div>
                        <label htmlFor="dobavljac">Dobavljač</label>
                        <input
                          type="text"
                          id="dobavljac"
                          aria-describedby="Dobavljač"
                          value={updateData?.dobavljac}
                          onChange={handleChange}
                          maxLength={64}
                          required
                        />
                      </div>
                      <div>
                        <label htmlFor="zemlja">Zemlja</label>
                        <select
                          id="zemlja"
                          aria-label="Odaberi zemlju"
                          required
                          value={updateData?.zemlja}
                          onChange={handleChange}>
                          <option value="">Odaberite zemlju</option>
                          <option value="SRBIJA">Srbija</option>
                          <option value="CRNAGORA">Crna Gora</option>
                        </select>
                      </div>
                      <div>
                        <label htmlFor="status">Status</label>
                        <select
                          id="status"
                          aria-label="Odaberi status"
                          required
                          value={updateData?.status}
                          onChange={handleChange}>
                          <option value="">Odaberite status</option>
                          <option value="NACRT">NACRT</option>
                          <option value="PROIZVODNJA">PROIZVODNJA</option>
                          <option value="TRANZIT">TRANZIT</option>
                          <option value="PRIMLJENA">PRIMLJENA</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* Podaci o kontejneru */}
                  <div className="mt-4">
                    <h4>Podaci o kontejneru</h4>
                    <div className=" grid gap-4 md:grid-cols-2 ">
                      <div>
                        <label htmlFor="brojKontejnera">Broj kontejnera</label>
                        <input
                          type="text"
                          id="brojKontejnera"
                          aria-describedby="Broj kontejnera"
                          value={updateData?.brojKontejnera}
                          onChange={handleChange}
                          maxLength={64}
                        />
                      </div>
                      <div>
                        <label htmlFor="spediter">Špediter</label>
                        <input
                          type="text"
                          id="spediter"
                          aria-describedby="Špediter"
                          value={updateData?.spediter}
                          onChange={handleChange}
                          maxLength={64}
                        />
                      </div>
                      <div>
                        <label htmlFor="datumPorudzbine">Datum porudžbine</label>
                        <div>
                          <DatePicker
                            id="datumPorudzbine"
                            locale="sr-Latn"
                            aria-describedby="Datum porudžbine"
                            autoComplete="off"
                            selected={updateData?.datumPorudzbine && new Date(updateData?.datumPorudzbine)}
                            onChange={(date) =>
                              setUpdateData((prev) => ({
                                ...prev,
                                datumPorudzbine: date,
                              }))
                            }
                            dateFormat="dd.MM.yyyy"
                            required
                          />
                        </div>
                      </div>
                      <div>
                        <label htmlFor="datumPolaska">Datum polaska</label>
                        <div>
                          <DatePicker
                            id="datumPolaska"
                            locale="sr-Latn"
                            aria-describedby="Datum polaska"
                            autoComplete="off"
                            selected={updateData?.datumPolaska && new Date(updateData?.datumPolaska)}
                            onChange={(date) =>
                              setUpdateData((prev) => ({
                                ...prev,
                                datumPolaska: date,
                              }))
                            }
                            dateFormat="dd.MM.yyyy"
                            required={(updateData.status === "TRANZIT" || "PRIMLJENA")}
                          />
                        </div>
                      </div>
                      <div>
                        <label htmlFor="datumPrijema">Datum prijema</label>
                        <div>
                          <DatePicker
                            id="datumPrijema"
                            locale="sr-Latn"
                            aria-describedby="Datum prijema"
                            autoComplete="off"
                            selected={updateData?.datumPrijema && new Date(updateData?.datumPrijema)}
                            onChange={(date) =>
                              setUpdateData((prev) => ({
                                ...prev,
                                datumPrijema: date,
                              }))
                            }
                            dateFormat="dd.MM.yyyy"
                            required={(updateData.status === "PRIMLJENA")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Komentar */}
                  <div className="mt-4">
                    <h4>Komentar</h4>

                    <div>
                      <label htmlFor="komentar">Komentar</label>
                      <textarea
                        type="text"
                        id="komentar"
                        aria-describedby="Komentar"
                        value={updateData?.komentar}
                        onChange={handleChange}
                        maxLength={512}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Modal Buttons */}

              <div className="flex flex-row-reverse gap-2">
                <button type="submit" className="button button-sky ms-2">
                  Sačuvaj
                </button>
                <button type="button" className="button button-gray" onClick={handleCancel}>
                  Odustani
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      {showSpinner && <Spinner />}

      {showSaveModal && (
        <Modal
          onOK={handleConfirmedSaveModal}
          onCancel={handleCancelSaveModal}
          title="Sačuvati izmene"
          question="Da li ste sigurni da želite da sačuvate izmene koje ste uneli?"
        />
      )}
    </div>
  );
};

export default ModalEditPorudzbina;
