import React, { useState } from "react";
import Modal from "../../components/Modal";
import Spinner from "../../components/Spinner";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ModalEditVrstaOtpdada = ({ setShowModalEdit, updateData, setUpdateData, fetchData }) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const handleCancelModal = () => {
    setShowSaveModal(false);
    setShowSpinner(false);
  };

  const handleSave = (e) => {
    e.preventDefault();
    setShowSaveModal(true);
  };

  const handleCancel = () => {
    setShowModalEdit(false);
    setShowSpinner(false);
  };

  const handleSaveOk = async () => {
    setShowSpinner(true);
    try {
      await axiosPrivate.put(`otpad/vrste-otpada/${updateData?.id}`, updateData);
      toast.success(`Vrsta otpada ${updateData?.vrstaOtpada} je uspešno sačuvana!`, {
        position: toast.POSITION.TOP_CENTER,
      });
      navigate("/otpad/vrste-otpada");
    } catch (error) {
      toast.error(`UPS!!! Došlo je do greške: ${error} `, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setShowSaveModal(true);
      setShowModalEdit(false);
      setShowSpinner(false);
      fetchData();
    }
  };

  const handleChange = (e) => {
    setUpdateData((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  return (
    <>
      {updateData && (
        <div className="relative z-10">
          <form onSubmit={(e) => handleSave(e)}>
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <div className="relative w-full max-w-2xl transform overflow-hidden rounded-lg bg-white p-4 text-left shadow-xl transition-all sm:p-8 dark:bg-gray-800">
                  <div className="w-full sm:mt-0">
                    {/* Modal Head */}

                    <h4>Izmena vrste otpada</h4>
                    <div className="my-4 h-0.5 bg-zinc-400"></div>

                    {/* Modal Body */}

                    <div className="grid grid-cols-1">
                      {/* Podaci o vrsti otpada */}

                      <h4>Podaci o vrsti otpada</h4>

                      <div>
                        <div className="col-lg-12 mb-3">
                          <label htmlFor="vrstaOtpada">Vrsta otpada</label>
                          <input
                            value={updateData?.vrstaOtpada}
                            type="text"
                            id="vrstaOtpada"
                            aria-describedby="Vrsta otpada"
                            required
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="my-4 h-0.5 bg-zinc-400"></div>
                  </div>
                  {/* Modal Buttons */}

                  <div className="flex flex-row-reverse gap-2">
                    <button type="submit" className="button button-sky" aria-describedby="Submit">
                      Sačuvaj
                    </button>
                    <button
                      type="button"
                      className="button button-gray"
                      aria-describedby="Cancel"
                      onClick={handleCancel}>
                      Odustani
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          {showSpinner && <Spinner />}
          {showSaveModal && (
            <Modal
              onOK={handleSaveOk}
              onCancel={handleCancelModal}
              title="Sačuvati izmene"
              question="Da li ste sigurni da želite da sačuvate izmene koje ste uneli?"
            />
          )}
        </div>
      )}
    </>
  );
};

export default ModalEditVrstaOtpdada;
