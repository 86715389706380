import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Modal from "../../components/Modal";
import { toast } from "react-toastify";
import Spinner from "../../components/Spinner";
import { useAuth } from "../../Context/AuthContext";

const NewDodeljivanjeOdsustva = () => {
  const { authUser } = useAuth();
  const [users, setUsers] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [dodeljenoOdsustvo, setDodeljenoOdsustvo] = useState({
    user: "",
    vrstaOdsustva: "",
    godina: new Date().getFullYear(),
    brojDana: 1,
    dodelioUser: authUser?.name,
  });

  const fetchUsers = async () => {
    setShowSpinner(true);

    try {
      const response = await axiosPrivate.get("users");
      setUsers(response?.data);
    } catch (error) {
      toast.error(`UPS!!! Došlo je do greške: ${error} `, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleOK = async () => {
    setShowSpinner(true);

    try {
      const response = await axiosPrivate.post(`odsustva/dodeljena`, dodeljenoOdsustvo);
      toast.success(
        `Novo odsustvo ${response?.data?.user} - ${response?.data?.vrstaOdsustva} - ${response?.data?.godina} je uspešno dodato!`,
        {
          position: toast.POSITION.TOP_CENTER,
        },
      );
      navigate("/odsustva/dodeljivanje");
    } catch (error) {
      toast.error(`UPS!!! Došlo je do greške: ${error} `, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setShowModal(false);
      setShowSpinner(false);
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    setDodeljenoOdsustvo({});
    setShowModal(false);
    setShowSpinner(false);
    navigate("/odsustva/dodeljivanje");
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleChangeString = (e) => {
    setDodeljenoOdsustvo((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };
  const handleChangeInteger = (e) => {
    setDodeljenoOdsustvo((prev) => ({
      ...prev,
      [e.target.id]: parseInt(e.target.value),
    }));
  };

  return (
    <>
      <div className="my-3 mt-16 flex h-full w-full items-center justify-center gap-2">
        <div className="w-full rounded-xl border-2 border-solid border-zinc-100 bg-gray-100 p-4  shadow-sm lg:w-1/2 dark:bg-gray-800">
          {/* Form component */}

          <form onSubmit={handleSubmit}>
            {/* User data */}

            <h3 className="mb-4 ">Dodeljivanje novog odsustva</h3>

            <div className="my-4 h-0.5 w-full bg-zinc-400"></div>

            <h4 className="my-3">Podaci o zaposlenom</h4>

            <div className="mb-3">
              <div className="col-lg-12 mb-3">
                <label htmlFor="user">Zaposleni</label>
                <select
                  id="user"
                  aria-label="Zaposleni"
                  required
                  value={dodeljenoOdsustvo?.user}
                  onChange={handleChangeString}>
                  <option value="">Odaberi zaposlenog</option>
                  {users &&
                    users.map((user, index) => (
                      <option key={index} value={user?.ime_prezime}>
                        {user?.ime_prezime}
                      </option>
                    ))}
                </select>
              </div>

              <div className="my-4 h-0.5 w-full bg-zinc-400"></div>

              <h4 className="my-3">Podaci o odsustvu</h4>

              <div className="col-lg-12 mb-3">
                <label htmlFor="vrstaOdsustva">Vrsta odsustva</label>
                <select
                  id="vrstaOdsustva"
                  aria-label="Vrsta odsustva"
                  required
                  value={dodeljenoOdsustvo?.vrstaOdsustva}
                  onChange={handleChangeString}>
                  <option value="">Odaberi vrstu odsustva</option>
                  <option value="GODISNJI_ODMOR">Godišnji odmor</option>
                </select>
              </div>
              <div className="col-lg-12 mb-3">
                <label htmlFor="godina">Godina</label>
                <select
                  id="godina"
                  aria-label="Odaberi godinu"
                  required
                  value={dodeljenoOdsustvo?.godina}
                  onChange={handleChangeInteger}>
                  {[...Array(5).keys()].map((i) => {
                    const year = new Date().getFullYear() - 1 + i;
                    return (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-lg-12 mb-3">
                <label htmlFor="godina">Broj dana</label>
                <input
                  value={dodeljenoOdsustvo?.brojDana}
                  type="number"
                  id="brojDana"
                  aria-describedby="Broj dana"
                  onChange={handleChangeInteger}
                  max={dodeljenoOdsustvo?.vrstaOdsustva === "GODISNJI_ODMOR" ? 30 : 5}
                  min="1"
                  required
                />
              </div>

              <div className="my-4 h-0.5 w-full bg-zinc-400"></div>

              <div className="float-end mb-3 mt-3 flex gap-2">
                <button type="submit" className="button button-gray" onClick={handleClose}>
                  Odustani
                </button>
                <button type="submit" className="button button-sky">
                  Dodaj
                </button>
              </div>
            </div>
          </form>
        </div>

        {/* Modal and Spinner component */}

        {showModal && (
          <Modal
            onOK={handleOK}
            onCancel={handleCancel}
            title="Potvrda dodavanja nove vrste otpada"
            question={`Da li ste sigurni da želite da dodelite novo odsustvo: ${dodeljenoOdsustvo?.user} - ${dodeljenoOdsustvo?.vrstaOdsustva} - ${dodeljenoOdsustvo?.godina}?`}
          />
        )}
        {showSpinner && <Spinner />}
      </div>
    </>
  );
};

export default NewDodeljivanjeOdsustva;
