import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";

const AppCards = ({ Links = [] }) => {
  const { authUser } = useAuth();

  return (
    Links && (
      <div className="my-4 grid grid-cols-2 gap-2 md:grid-cols-4 md:gap-4 2xl:grid-cols-6 ">
        {Links.map(
          (dataItem, index) =>
            authUser?.role_id > dataItem?.minRole && (
              <Link key={index} to={dataItem?.href}>
                <div className="before: min-h-96 overflow-hidden rounded-md border-2 border-solid  border-zinc-100 shadow-lg duration-150 hover:scale-105">
                  <img className="w-full" src={dataItem?.image} alt={dataItem?.label} />
                  <div className="px-2 py-4 md:px-6">
                    <h4>{dataItem?.label}</h4>
                    <div className="my-2 h-0.5 bg-zinc-400"></div>
                    <p>{dataItem?.desc}</p>
                  </div>
                </div>
              </Link>
            ),
        )}
      </div>
    )
  );
};

export default AppCards;
