import React, { useState, useEffect } from "react";
import Modal from "../../components/Modal";
import Spinner from "../../components/Spinner";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";

const ModalEditDodeljivanjeOdsustva = ({
  setShowModalEdit,
  updateData,
  setUpdateData,
  fetchData,
}) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [users, setUsers] = useState(null);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  const fetchUsers = async () => {
    setShowSpinner(true);

    try {
      const response = await axiosPrivate.get("users");
      setUsers(response?.data);
    } catch (error) {
      toast.error(`UPS!!! Došlo je do greške: ${error} `, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleCancelModal = () => {
    setShowSaveModal(false);
    setShowSpinner(false);
  };

  const handleSave = (e) => {
    e.preventDefault();
    setShowSaveModal(true);
  };

  const handleCancel = () => {
    setShowModalEdit(false);
    setShowSpinner(false);
  };

  const handleSaveOk = async () => {
    setShowSpinner(true);
    try {
      await axiosPrivate.put(
        `odsustva/dodeljena/${updateData?.id}`,
        updateData,
      );
      toast.success(
        `Dodeljeno odsustvo ${updateData?.user} - ${updateData?.vrstaOdsustva} - ${updateData?.godina} je uspešno sačuvano!`,
        {
          position: toast.POSITION.TOP_CENTER,
        },
      );
    } catch (error) {
      toast.error(`UPS!!! Došlo je do greške: ${error} `, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setShowSaveModal(true);
      setShowModalEdit(false);
      setShowSpinner(false);
      fetchData();
    }
  };

  const handleChangeString = (e) => {
    setUpdateData((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };
  const handleChangeInteger = (e) => {
    setUpdateData((prev) => ({
      ...prev,
      [e.target.id]: parseInt(e.target.value),
    }));
  };

  return (
    <>
      {updateData && (
        <div className="relative z-10">
          <form onSubmit={(e) => handleSave(e)}>
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <div className="relative w-full max-w-2xl transform overflow-hidden rounded-lg bg-white p-4 text-left shadow-xl transition-all sm:p-8 dark:bg-gray-800">
                  <div className="w-full sm:mt-0">
                    {/* Modal Head */}

                    <h4>Izmena dodeljenog odsustva</h4>
                    <div className="my-4 h-0.5 bg-zinc-400"></div>

                    {/* Modal Body */}

                    <div className="grid grid-cols-1">
                      {/* Podaci o odsustvu */}

                      <h4>Podaci o dodeljenom odsustvu</h4>

                      <div>
                        <div className="col-lg-12 mb-3">
                          <label htmlFor="user">Zaposleni</label>
                          <select
                            id="user"
                            aria-label="Zaposleni"
                            required
                            value={updateData?.user}
                            onChange={handleChangeString}
                          >
                            {users &&
                              users.map((user, index) => (
                                <option key={index} value={user?.ime_prezime}>
                                  {user?.ime_prezime}
                                </option>
                              ))}
                          </select>
                        </div>

                        <div className="col-lg-12 mb-3">
                          <label htmlFor="vrstaOdsustva">Vrsta odsustva</label>
                          <select
                            id="vrstaOdsustva"
                            aria-label="Vrsta odsustva"
                            required
                            value={updateData?.vrstaOdsustva}
                            onChange={handleChangeString}
                          >
                            <option value="GODISNJI_ODMOR">
                              Godišnji odmor
                            </option>
                            <option value="PLACENO_ODSUSTVO">
                              Plaćeno odsustvo
                            </option>
                          </select>
                        </div>
                        <div className="col-lg-12 mb-3">
                          <label htmlFor="godina">Godina</label>
                          <select
                            id="godina"
                            aria-label="Odaberi godinu"
                            required
                            value={updateData?.godina}
                            onChange={handleChangeInteger}
                          >
                            {[...Array(5).keys()].map((i) => {
                              const year = new Date().getFullYear() - 1 + i;
                              return (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-lg-12 mb-3">
                          <label htmlFor="godina">Broj dana</label>
                          <input
                            value={updateData?.brojDana}
                            type="number"
                            id="brojDana"
                            aria-describedby="Broj dana"
                            onChange={handleChangeInteger}
                            max={
                              updateData?.vrstaOdsustva ===
                              "GODISNJI_ODMOR"
                                ? 30
                                : 5
                            }
                            min="1"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="my-4 h-0.5 bg-zinc-400"></div>
                  </div>
                  {/* Modal Buttons */}

                  <div className="flex flex-row-reverse gap-2">
                    <button
                      type="submit"
                      className="button button-sky"
                      aria-describedby="Submit"
                    >
                      Sačuvaj
                    </button>
                    <button
                      type="button"
                      className="button button-gray"
                      aria-describedby="Cancel"
                      onClick={handleCancel}
                    >
                      Odustani
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {showSpinner && <Spinner />}
            {showSaveModal && (
              <Modal
                onOK={handleSaveOk}
                onCancel={handleCancelModal}
                title="Sačuvati izmene"
                question="Da li ste sigurni da želite da sačuvate izmene koje ste uneli?"
              />
            )}
          </form>
        </div>
      )}
    </>
  );
};

export default ModalEditDodeljivanjeOdsustva;
