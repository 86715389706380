import React, { useState, useEffect } from "react";
import { produce } from "immer";
import Modal from "../../components/Modal";
import Spinner from "../../components/Spinner";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ModalEditJCI = ({ setShowModalEdit, updateData, setUpdateData, fetchData }) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [proizvodi, setProizvodi] = useState(null);
  // implement logic for new created products
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const fetchProizvodi = async () => {
    setShowSpinner(true);

    try {
      const response = await axiosPrivate.get("otpad/proizvodi");
      setProizvodi(response?.data);
    } catch (error) {
      toast.error(`UPS!!! Došlo je do greške pri preuzimanju proizvoda: ${error} `, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    fetchProizvodi();
  }, []);

  const handleCancelModal = () => {
    setShowSaveModal(false);
    setShowSpinner(false);
  };

  const handleSave = (e) => {
    e.preventDefault();
    setShowSaveModal(true);
  };

  const handleCancel = () => {
    setShowModalEdit(false);
    setShowSpinner(false);
  };

  const onConfirmedSave = async () => {
    setShowSpinner(true);
    try {
      await axiosPrivate.put(`otpad/evidencija/${updateData?.id}`, updateData);
      toast.success(`JCI broj ${updateData?.brojJci} je uspešno sačuvana!`, {
        position: toast.POSITION.TOP_CENTER,
      });
      navigate("/otpad/evidencija");
    } catch (error) {
      toast.error(`UPS!!! Došlo je do greške: ${error} `, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setShowSaveModal(true);
      setShowModalEdit(false);
      setShowSpinner(false);
      fetchData();
    }
  };

  const handleChangeJCI = (e) => {
    setUpdateData((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const handleChangeProizvod = (e) => {
    if (e.target.value >= 0) {
      const newData = produce(updateData, (draft) => {
        draft.jciProizvodi[e.target.id].kolicina = e.target.value >= 0 ? parseFloat(e.target.value) : 0;
      });
      setUpdateData(newData);
    } else {
      e.target.value = 0;
    }
  };

  return (
    <>
      {updateData && (
        <div className="relative z-10">
          <form onSubmit={(e) => handleSave(e)}>
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <div className="relative max-w-6xl transform overflow-hidden rounded-lg bg-white p-4 text-left shadow-xl transition-all sm:p-8 dark:bg-gray-800">
                  <div className=" sm:mt-0">
                    {/* Modal Head */}

                    <h4>Izmena podataka na JCI</h4>
                    <div className="my-4 h-0.5 bg-zinc-400"></div>

                    {/* Modal Body */}

                    <div className="grid grid-cols-1">
                      <h4>Podaci o JCI</h4>

                      <div className="grid grid-cols-2 gap-4 xl:grid-cols-4">
                        <div>
                          <label htmlFor="brojJci">Broj JCI</label>
                          <input
                            value={updateData?.brojJci}
                            type="text"
                            id="brojJci"
                            aria-describedby="Broj JCI"
                            required
                            onChange={handleChangeJCI}
                          />
                        </div>

                        <div>
                          <label htmlFor="datum">Datum JCI</label>
                          <DatePicker
                            id="datum"
                            locale="sr-Latn"
                            aria-describedby="Datum JCI"
                            autoComplete="off"
                            selected={new Date(updateData?.datum)}
                            onChange={(date) =>
                              setUpdateData((prev) => ({
                                ...prev,
                                datum: date,
                              }))
                            }
                            dateFormat="dd.MM.yyyy"
                            required
                          />
                        </div>

                        <div>
                          <label htmlFor="zemlja">Zemlja</label>
                          <select
                            id="zemlja"
                            aria-label="Odaberi zemlju"
                            required
                            value={updateData?.zemlja}
                            onChange={handleChangeJCI}>
                            <option value="">Odaberite zemlju</option>
                            <option value="SRBIJA">Srbija</option>
                            <option value="CRNAGORA">Crna Gora</option>
                          </select>
                        </div>
                        <div>
                          <label htmlFor="operacija">Operacija</label>
                          <select
                            id="operacija"
                            aria-label="Odaberi operaciju"
                            required
                            value={updateData?.operacija}
                            onChange={handleChangeJCI}>
                            <option value="">Odaberite operaciju</option>
                            <option value="UVOZ">Uvoz</option>
                            <option value="IZVOZ">Izvoz</option>
                          </select>
                        </div>
                      </div>

                      <div>
                        <h4 className="my-3">Podaci o proizvodima</h4>

                        <div className="grid grid-cols-2 gap-4">
                          {updateData &&
                            updateData?.jciProizvodi.map((row, index) => (
                              <div key={`proizvod_${index}`}>
                                <label>{row?.proizvod?.proizvod}</label>
                                <input
                                  type="number"
                                  step="0.001"
                                  id={index}
                                  aria-describedby="Kolicina proizvoda"
                                  value={row?.kolicina}
                                  onChange={handleChangeProizvod}
                                  required
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>

                    <div className="my-4 h-0.5 bg-zinc-400"></div>
                  </div>
                  {/* Modal Buttons */}

                  <div className="flex flex-row-reverse gap-2">
                    <button type="submit" className="button button-sky" aria-describedby="Submit">
                      Sačuvaj
                    </button>
                    <button
                      type="button"
                      className="button button-gray"
                      aria-describedby="Cancel"
                      onClick={handleCancel}>
                      Odustani
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {showSpinner && <Spinner />}
            {showSaveModal && (
              <Modal
                onOK={onConfirmedSave}
                onCancel={handleCancelModal}
                title="Sačuvati izmene"
                question="Da li ste sigurni da želite da sačuvate izmene koje ste uneli?"
              />
            )}
          </form>
        </div>
      )}
    </>
  );
};

export default ModalEditJCI;
