import React, { useState, useEffect } from "react";
import { produce } from "immer";
import Modal from "../../components/Modal";
import Spinner from "../../components/Spinner";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ModalEditProizvod = ({ setShowModalEdit, updateData, setUpdateData, fetchData }) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const fetchDataVrsteOtpada = async () => {
    setShowSpinner(true);

    try {
      const response = await axiosPrivate.get("otpad/vrste-otpada");
      updateProductData(response?.data, updateData);
    } catch (error) {
      toast.error(`UPS!!! Došlo je do greške pri preuzimanju podataka: ${error} `, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setShowSpinner(false);
    }
  };

  const updateProductData = (sveVrsteOtpada, postojeceVrsteOtpada) => {
    setShowSpinner(true);

    let proizvodPodaci = {
      ...postojeceVrsteOtpada,
    };

    sveVrsteOtpada.forEach((otpadItem) => {
      if (!postojeceVrsteOtpada?.ProizvodMasaOtpada.some((item) => item?.vrsteOtpadaId === otpadItem?.id)) {
        proizvodPodaci?.ProizvodMasaOtpada.push({
          id: 0,
          masa: 0,
          proizvodiId: proizvodPodaci?.id,
          vrsteOtpadaId: otpadItem?.id,
          VrstaOtpada: {
            id: otpadItem?.id,
            vrstaOtpada: otpadItem?.vrstaOtpada,
          },
        });
      }
    });

    setUpdateData(proizvodPodaci);
    setShowSpinner(false);
  };

  useEffect(() => {
    fetchDataVrsteOtpada();
  }, []);

  const handleCancelModal = () => {
    setShowSaveModal(false);
    setShowSpinner(false);
  };

  const handleSave = (e) => {
    e.preventDefault();
    setShowSaveModal(true);
  };

  const handleCancel = () => {
    setShowModalEdit(false);
    setShowSpinner(false);
  };

  const handleSaveOk = async () => {
    setShowSpinner(true);
    try {
      await axiosPrivate.put(`otpad/proizvodi/${updateData?.id}`, updateData);
      toast.success(`Vrsta proizvoda ${updateData?.proizvod} je uspešno sačuvana!`, {
        position: toast.POSITION.TOP_CENTER,
      });
      navigate("/otpad/proizvodi");
    } catch (error) {
      toast.error(`UPS!!! Došlo je do greške: ${error} `, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setShowSaveModal(true);
      setShowModalEdit(false);
      setShowSpinner(false);
      fetchData();
    }
  };

  const handleChangeProizvod = (e) => {
    setUpdateData((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const handleChangeOtpad = (e) => {
    if (e.target.value >= 0) {
      const newData = produce(updateData, (draft) => {
        draft.ProizvodMasaOtpada[e.target.id].masa = e.target.value >= 0 ? parseFloat(e.target.value) : 0;
      });
      setUpdateData(newData);
    } else {
      e.target.value = 0;
    }
  };

  return (
    <>
      {updateData && (
        <div className="relative z-10">
          <form onSubmit={(e) => handleSave(e)}>
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <div className="relative w-full max-w-2xl transform overflow-hidden rounded-lg bg-white p-4 text-left shadow-xl transition-all sm:p-8 dark:bg-gray-800">
                  <div className="w-full sm:mt-0">
                    {/* Modal Head */}

                    <h4>Izmena vrste proizvoda</h4>
                    <div className="my-4 h-0.5 bg-zinc-400"></div>

                    {/* Modal Body */}

                    <div className="grid grid-cols-1">
                      {/* Podaci o korisniku */}

                      <h4>Podaci o vrsti proizvoda</h4>

                      <div>
                        <div className="col-lg-12 mb-3">
                          <label htmlFor="proizvod">Vrsta proizvoda</label>
                          <input
                            value={updateData?.proizvod}
                            type="text"
                            id="proizvod"
                            aria-describedby="Vrsta proizvoda"
                            required
                            onChange={handleChangeProizvod}
                          />

                          <h4 className="my-3">Parametrizacija otpada</h4>

                          {updateData &&
                            updateData?.ProizvodMasaOtpada.map((row, index) => (
                              <div key={`vrstaOtpada_${index}`}>
                                <label>{row?.VrstaOtpada?.vrstaOtpada}</label>
                                <input
                                  type="number"
                                  step="0.001"
                                  id={index}
                                  aria-describedby="Kolicina vrste otpada"
                                  value={row?.masa}
                                  onChange={handleChangeOtpad}
                                  maxLength={190}
                                  required
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className="my-4 h-0.5 bg-zinc-400"></div>
                  </div>
                  {/* Modal Buttons */}

                  <div className="flex flex-row-reverse gap-2">
                    <button type="submit" className="button button-sky" aria-describedby="Submit">
                      Sačuvaj
                    </button>
                    <button
                      type="button"
                      className="button button-gray"
                      aria-describedby="Cancel"
                      onClick={handleCancel}>
                      Odustani
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
      {showSpinner && <Spinner />}
      {showSaveModal && (
        <Modal
          onOK={handleSaveOk}
          onCancel={handleCancelModal}
          title="Sačuvati izmene"
          question="Da li ste sigurni da želite da sačuvate izmene koje ste uneli?"
        />
      )}
    </>
  );
};

export default ModalEditProizvod;
