import React from "react";
import { Outlet } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import { toast } from "react-toastify";

const ProtectRoute = ({ minRole = 5000 }) => {
  const { authUser } = useAuth();

  if (authUser && authUser?.role_id > minRole) {
    return <Outlet />;
  } else {
    toast.warning(
      <div>
        UPS!!! Izgleda da niste autorizovani da posetite ovu lokaciju!
        <br /> Bićete preusmereni na početnu stranu...
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      },
    );
    setTimeout(() => {
      window.location.href = "/";
    }, 3500);
  }
};

export default ProtectRoute;
