import React, { createContext, useContext, useState } from "react";
import { googleLogout } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { ApiLoginConnector, ApiLogoutConnector, ApiRefreshConnector } from "../components/ApiAuthConnectors";
import { toast } from "react-toastify";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  const handleLogin = async (response) => {
    try {
      const authResponse = await ApiLoginConnector({
        data: response,
      });
      const accessToken = authResponse?.data?.accessToken;
      const decodedAccessToken = jwtDecode(accessToken);

      if (decodedAccessToken?.exp > Date.now()) {
        setAuthUser(decodedAccessToken?.user);
        setAccessToken(accessToken);
      }
    } catch (err) {
      toast.warning(`Nemate ovlascenja za pristup aplikaciji`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleLogoutOK = async () => {
    try {
      await ApiLogoutConnector();
      googleLogout();
      setAuthUser(null);
      setAccessToken(null);
      toast.success(`Uspešno ste se odjavili`, {
        position: "top-center",
      });
    } catch (err) {
      toast.warning(`Ups! Došlo je do greške: ${err}`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const refreshAccessToken = async () => {
    try {
      const refreshTokenResponse = await ApiRefreshConnector();
      if (refreshTokenResponse) {
        const newAccessToken = refreshTokenResponse?.data?.accessToken;
        const decodedAccessToken = jwtDecode(newAccessToken);
        setAuthUser(decodedAccessToken?.user);
        setAccessToken(newAccessToken);
        return newAccessToken;
      }
    } catch (err) {
      toast.warning(`Ulogujte se kako biste pristupili aplikaciji`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        authUser,
        setAuthUser,
        accessToken,
        setAccessToken,
        handleLogin,
        handleLogoutOK,
        refreshAccessToken,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
