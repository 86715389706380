import React, { useEffect, useState, useRef } from "react";
import { format } from "date-fns";
import { toast } from "react-toastify";
import Spinner from "../../components/Spinner";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { DownloadTableExcel } from "react-export-table-to-excel";
import HandleFiles from "../../components/HandleFiles";

const DelovodnikReklamacija = () => {
  const [tableData, setTableData] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [filter, setFilter] = useState({ zemlja_reklamacije: "" });
  const [selectedRowFiles, setSelectedRowFiles] = useState(null);
  const [showHandleFiles, setShowHandleFiles] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const tableHeaders = [
    "Broj reklamacije",
    "Zemlja reklamacije",
    "Status reklamacije",
    "Datum prijema",
    "Odgovorna osoba",
    "Ime i prezime",
    "Adresa",
    "Telefon",
    "Email",
    "Datum kupovine",
    "Broj računa",
    "Naziv proizvoda",
    "Opis reklamacije",
    "Datum odgovora",
    "Opis odluke",
    "Komentar",
    "Datoteke",
    "SMS je poslat",
  ];
  const tableRef = useRef(null);

  const fetchData = async () => {
    setShowSpinner(true);

    try {
      const response = await axiosPrivate.get(
        `reklamacije?sortBy=datum_prijema&sortOrder=desc${filter?.zemlja_reklamacije !== "" ? `&zemlja_reklamacije=${filter?.zemlja_reklamacije}` : ""}`,
      );
      setTableData(response?.data?.data);
    } catch (error) {
      toast.error(`UPS!!! Došlo je do greške: ${error} `, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filter]);

  const handleChangeFilter = (e) => {
    setFilter((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const handleShowFiles = (row) => {
    setSelectedRowFiles(row);
    setShowHandleFiles(true);
  };

  return (
    <>
      <h3 className="mt-4 ">Reklamacije - Delovodna knjiga</h3>

      <div className="grid grid-cols-1 justify-end gap-4 md:flex">
        <div className=" flex justify-end gap-4">
          <label htmlFor="zemlja_reklamacije">Zemlja reklamacije: </label>
          <form>
            <select
              id="zemlja_reklamacije"
              aria-label="Odaberi zemlju"
              required
              value={filter?.zemlja_reklamacije}
              onChange={handleChangeFilter}>
              <option value="">Sve zemlje</option>
              <option value="SRBIJA">Srbija</option>
              <option value="CRNAGORA">Crna Gora</option>
            </select>
          </form>
        </div>
        <div className=" flex justify-end gap-4">
          <DownloadTableExcel
            filename="Delovodnik reklamacija"
            sheet="Delovodna knjiga"
            currentTableRef={tableRef.current}>
            <button className="button button-sky"> Izvezi u Excel </button>
          </DownloadTableExcel>
        </div>
      </div>

      {tableData.length ? (
        <div className="relative my-4 overflow-x-auto border-2 p-3 shadow-lg shadow-slate-700 sm:rounded-lg">
          <table ref={tableRef} className="w-full text-left text-sm text-zinc-500 rtl:text-right dark:text-zinc-400 ">
            <thead className="text-s bg-zinc-200 uppercase text-zinc-600 dark:bg-zinc-700 dark:text-zinc-400">
              <tr>
                {tableHeaders.map((tableKey, index) => (
                  <th className="px-6 py-3" key={index}>
                    {tableKey}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {tableData.map((row, index) => (
                <tr key={index} className="border-b bg-white hover:!bg-zinc-100 dark:border-zinc-700 dark:bg-zinc-800">
                  <td key={`broj_reklamacije_${index}`}>
                    <a
                      key={`broj_reklamacije_${index}`}
                      className="font-medium text-sky-500 no-underline  hover:cursor-pointer hover:text-sky-400"
                      href={`/reklamacije/pregled-reklamacije/${row?.broj_reklamacije}`}
                      target="blank"
                      rel="noreferrer noopener">
                      {row?.broj_reklamacije}
                    </a>
                  </td>
                  <td key={`zemlja_reklamacije_${index}`}>{row?.zemlja_reklamacije}</td>
                  <td
                    className={`whitespace-nowrap px-6 py-4 font-medium text-zinc-600 dark:text-white ${
                      row?.status_reklamacije === "OPRAVDANA"
                        ? `bg-green-300`
                        : row?.status_reklamacije === "NEOPRAVDANA"
                          ? `bg-red-300`
                          : `bg-zinc-300`
                    }`}
                    key={`status_reklamacije_${index}`}>
                    {row?.status_reklamacije}
                  </td>
                  <td key={`datum_prijema_${index}`}>
                    {row?.datum_prijema && format(row?.datum_prijema, "dd.MM.yyyy")}
                  </td>
                  <td key={`odgovorna_osoba_${index}`}>{row?.odgovorna_osoba}</td>
                  <td key={`ime_prezime_${index}`}>{row?.ime_prezime}</td>
                  <td key={`adresa_${index}`}>{row?.adresa}</td>
                  <td key={`telefon_${index}`}>{row?.telefon}</td>
                  <td key={`email_${index}`}>{row?.email}</td>
                  <td key={`datum_kupovine_${index}`}>
                    {row?.datum_kupovine && format(row?.datum_kupovine, "dd.MM.yyyy")}
                  </td>
                  <td key={`broj_racuna_${index}`}>{row?.broj_racuna}</td>
                  <td key={`naziv_poizvoda_${index}`}>{row?.naziv_poizvoda}</td>
                  <td
                    key={`opis_reklamacije_${index}`}
                    style={{
                      maxWidth: "400px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}>
                    {row?.opis_reklamacije}
                  </td>
                  <td key={`datum_odgovora_${index}`}>
                    {row?.datum_odgovora && format(row?.datum_odgovora, "dd.MM.yyyy")}
                  </td>
                  <td
                    key={`opis_odluke_${index}`}
                    style={{
                      maxWidth: "400px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}>
                    {row?.opis_odluke}
                  </td>
                  <td
                    key={`komentar_${index}`}
                    style={{
                      maxWidth: "400px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}>
                    {row?.komentar}
                  </td>
                  <td>
                  <button
                    key={`datoteke_list_${index}`}
                    className="button button-sky"
                    onClick={() => handleShowFiles(row)}>
                    Datoteke - prikačeno {row?.files ? JSON.parse(row?.files).length : "0"}
                  </button>
                  </td>
                  <td key={`files_${index}`}>
                    <input
                      type="checkbox"
                      checked={row?.sms_sent}
                      disabled
                      className="h-4 w-4 appearance-auto rounded border-zinc-300 bg-zinc-100 p-2 text-zinc-600 focus:ring-2 focus:ring-zinc-500 dark:border-zinc-600 dark:bg-zinc-700 dark:ring-offset-zinc-800 dark:focus:ring-zinc-600"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        !showSpinner && <h4 className="my-4 text-zinc-600 ">Nema reklamacija koje su u prijemu...</h4>
      )}

      {showSpinner && <Spinner />}

      {showHandleFiles && (
        <HandleFiles
          url="reklamacije"
          id={selectedRowFiles?.broj_reklamacije}
          data={selectedRowFiles}
          fetchData={fetchData}
          setShowHandleFiles={setShowHandleFiles}
        />
      )}
    </>
  );
};

export default DelovodnikReklamacija;
